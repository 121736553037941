@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        scrollbar-width: none;
        scroll-behavior: smooth;
    }
    *::-webkit-scrollbar {
        width: 0;
    }
    html {
        scroll-padding-top: 0;
        overflow-x: hidden;
    }
    body {
        @apply font-secondary;
        overflow: hidden;
    }

    html,
    body,
    #root {
        width: 100vw;
        height: 100vh;
        margin: 0;
        padding: 0;
    }

    h1 {
        @apply font-primary;
    }
}

@layer components {
    .btn {
        @apply px-6 py-2 bg-ciel text-darkGreen text-sm font-medium rounded-sm;
    }
    .bg-cover-center {
        @apply bg-center bg-cover bg-no-repeat;
    }
    .about-text {
        @apply px-4 md:px-8 leading-10;
    }
    .music-item {
        @apply flex items-center font-extrabold px-8 cursor-pointer py-1;
    }
    .music-item__closed {
        @apply border-darkGreen text-darkGreen bg-transparent;
    }
    .music-item-split-screen__closed {
        @apply border-isabelline text-isabelline bg-darkGreen lg:border-darkGreen lg:text-darkGreen lg:bg-transparent;
    }
    .music-item__open {
        @apply border-darkGreen;
    }
    .music-item-title__closed {
        @apply text-3xl lg:text-4xl lg:py-4 justify-between;
    }
    .music-item-title__open {
        @apply text-4xl justify-start lg:text-center border-none py-8 lg:py-2;
    }
    .project-item {
        @apply music-item music-item__open music-item-title__closed;
    }
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel-ExtraLight.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel-ExtraLightItalic.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel-Light.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel-Light.woff') format('woff');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel-LightItalic.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel-LightItalic.woff') format('woff');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel.woff') format('woff');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel-Italic.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel-Italic.woff') format('woff');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel-Medium.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel-MediumItalic.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel-Bold.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel-Bold.woff') format('woff');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel-BoldItalic.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel-BoldItalic.woff') format('woff');
    font-weight: 600;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel-ExtraBold.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel-ExtraBold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'NeueKabel';
    src: url('./assets/fonts/NeueKabel/NeueKabel-ExtraBoldItalic.otf') format('otf'),
        url('./assets/fonts/NeueKabel/NeueKabel-ExtraBoldItalic.woff') format('woff');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}
